module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"micoca-cola.bo","short_name":"micoca-cola.bo","start_url":"/","background_color":"#921919","theme_color":"#921919","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","screenshots":[{"src":"narrow-screenshot.png","type":"image/png","sizes":"1290x2796","form_factor":"narrow"},{"src":"wide-screenshot.png","type":"image/jpg","sizes":"1902x920","form_factor":"wide"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bbf27c86514d39386aec12d90704451f"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":900},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":""},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5NJGPS5","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"ecommerce"}},"routeChangeEventName":"ecommerce-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
